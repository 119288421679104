@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.competitions {
  $this: &;

  margin-top: 40px;

  &__items {
    border: 1px solid $border;
    border-radius: 6px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 18px 20px;
    border-bottom: 1px solid $border;

    &:last-child {
      border: 0;
    }

    @include mobile() {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__button {
    display: inline-block;
    vertical-align: middle;
    margin-left: 28px;
  }

  &__name {
    @include stolzl();
    font-size: 16px;
    font-weight: 500;
  }

  &__date {
    margin-left: auto;
    font-size: 13px;
    @include mobile() {
      flex-direction: column;
      margin: 12px 0 0 0;
    }
  }

  &__empty {
    padding: 20px 0;
    text-align: center;
    font-style: italic;
    font-size: 14px;
  }
}