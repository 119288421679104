@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.stats {
  $this: &;
  display: flex;

  @include mobile() {
    flex-direction: column;
  }

  &__main {
    flex:1;
  }

  &__totals {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__total {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  &__cities {
    margin-left: 30px;
    width: 300px;
    @include mobile() {
      width: 100%;
      margin: 30px 0 0 0;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;

    &--three {
      #{$this}__item {
        width: 33.33%;
        @include mobile() {
          width: 100%;
        }
      }
    }
  }

  &__item {
    border: 1px solid $border;
    margin-left: -1px;
    margin-top: -1px;
    width: 50%;
    padding: 15px;
    @include mobile() {
      width: 100%;
    }
  }

  &__discipline {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
  }

  &__result {
    font-size: 13px;
    font-weight: normal;
    margin-left: auto;
    padding-left: 5px;
  }

  &__gender {
    display: flex;
    align-items: center;
    margin-top: 10px;

  }

  &__icon {
      width: 12px;
      height: 16px;
      display: block;
      margin-right: 4px;
  }

  &__group {
    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-top: 2px;
  }
}