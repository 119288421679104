@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
html {
  @include proxima();
  color: $black;
  font-size: 16px;
  line-height: 1.4;
  background: $white;
  overflow-x: hidden;
  overflow-wrap: break-word;
  scroll-behavior: smooth;
  @include mobile() {
    font-size: 14px;
  }
}

@media print {
  @page {
    margin: 1cm;
  }
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

html, body {
  min-height: 100vh;
}

.c {
  margin-left: auto;
  margin-right: auto;
  max-width: $container-lg;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  //@include media('lg') {
  //  max-width: $container-md;
  //}
  @include mobile() {
    padding-left: 10px;
    padding-right: 10px;
  }
}

img[data-src],
img[data-srcset] {
  min-height: 1px;
}

.hidden {
  display: none;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.small {
  font-size: 0.85rem;
  opacity: 0.5;
}

.muted {
  color: $muted;
}

.w0 {
  width: 0;
}

.w100 {
  width: 100%;
}

.desktop {
  @include mobile() {
    display: none;
  }
}

.mobile {
  @include not-mobile() {
    display: none;
  }
}

.animated {
  & > * {
    visibility: hidden;
  }
}

.nowrap {
  white-space: nowrap;
}

.content {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}


.link {
  color: $black;
  //border-bottom: 1px solid $dark-gray;
  padding-bottom: 10px;
  text-decoration: none;
  @include anim();

  &:hover {
    text-decoration: none;
    border-color: $black;
  }
}


.table {
  border-radius: 4px;
  border: 1px solid $border;
  @include mobile() {
    font-size: 12px;
  }

  table {
    width: 100%;
  }

  th, td {
    vertical-align: middle;
    padding: 9px 12px;
    @include mobile() {
      padding: 5px 8px;
    }
  }

  th {
    font-size: 12px;
    font-weight: 600;
    height: 34px;
    border-bottom: 1px solid $border;
    white-space: nowrap;
    @include mobile() {
      font-size: 12px;
    }
  }

  td {
    border-top: 1px solid $border;
  }

  tr:first-child {
    td {
      border-top: 0;
    }
  }

  &__subhead {
    td {
      background: #F9F9F9;
      @include mobile() {
        font-size: 13px;
      }
    }
  }

  &__empty {
    padding: 20px 0;
    text-align: center;
    font-style: italic;
    font-size: 14px;
  }

  &__position {
    width: 14px;
    height: 14px;
    display: block;
    @include mobile() {
      width: 10px;
      height: 10px;
    }
  }

}

.block {
  margin-bottom: 34px;

  h1, h2 {
    margin-bottom: 16px;
  }

}

.top {
  margin-top: 10px;
  @include mobile() {
    span {
      display: block;
    }
  }
}