@mixin cf() {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin anim($duration: .1s) {
  transition: all $duration ease-in;
}

@mixin media-max-width($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin mobile() {
  @media screen and (max-width: $breakpoint-mobile - 1) {
    @content;
  }
}

@mixin not-mobile() {
  @media screen and (min-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

@mixin proxima() {
  font-family: 'Proxima Nova', sans-serif;
}

@mixin stolzl() {
  font-family: 'Stolzl', sans-serif;
}