@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.competition {
  $this: &;

  &__info {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 35px;
  }

  &__label {
    display: flex;
    align-items: center;
    margin-right: 18px;

    svg {
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
  }
}