@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.leaders {
  $this: &;

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 50px;
    @include mobile() {
      margin: 0 0 35px;
    }
  }

  &__item {
    width: 50%;
    padding: 15px;
    @include mobile() {
      width: 100%;
      padding: 0;
      margin-bottom: 25px;
    }
  }

  &__link {
    margin-bottom: 12px;

    a {
      @include stolzl();
      font-size: 18px;
      font-weight: 500;
    }
  }
}