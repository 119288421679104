@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.filter {
  $this: &;

  margin-top: -1px;
  background: linear-gradient(90deg, #4095E0 0%, #4EC6AE 100%);

  &__container {
    display: flex;
    flex-wrap: wrap;
    height: 70px;
    font-size: 14px;
    font-weight: 600;
    color: $white;
    @include mobile() {
      padding: 6px 0;
      height: auto;
    }
  }

  &__block {
    height: 100%;
    margin-right: 40px;
    display: flex;
    @include mobile() {
      margin: 4px 22px 4px 0;
    }
  }

  &__icon {
    display: block;
    width: 22px;
    height: 22px;
  }

  &__label {
    align-self: center;
    margin-right: 10px;
    margin-left: 6px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 6px;
    opacity: .7;
    position: relative;
    color: $white;
    @include anim();
    @include mobile() {
      height: 25px;
    }

    &:hover {
      opacity: 1;
      text-decoration: none;
    }

    &--active {
      opacity: 1;

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 3px;
        background: $white;
        bottom: -9px;
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
        @include mobile() {
          display: none;
        }
      }
    }
  }

  &__logo {
    margin-left: auto;
    align-self: center;
    color: $white;

    img {
      display: block;
    }

    @include mobile() {
      display: none;
    }
  }
}