@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl/subset-Stolzl-Regular.woff2') format('woff2'),
  url('../fonts/Stolzl/subset-Stolzl-Regular.woff') format('woff'),
  url('../fonts/Stolzl/subset-Stolzl-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl Book';
  src: url('../fonts/Stolzl/subset-Stolzl-Book.woff2') format('woff2'),
  url('../fonts/Stolzl/subset-Stolzl-Book.woff') format('woff'),
  url('../fonts/Stolzl/subset-Stolzl-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl/subset-Stolzl-Thin.woff2') format('woff2'),
  url('../fonts/Stolzl/subset-Stolzl-Thin.woff') format('woff'),
  url('../fonts/Stolzl/subset-Stolzl-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl/subset-Stolzl-Light.woff2') format('woff2'),
  url('../fonts/Stolzl/subset-Stolzl-Light.woff') format('woff'),
  url('../fonts/Stolzl/subset-Stolzl-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl/subset-Stolzl-Medium.woff2') format('woff2'),
  url('../fonts/Stolzl/subset-Stolzl-Medium.woff') format('woff'),
  url('../fonts/Stolzl/subset-Stolzl-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stolzl';
  src: url('../fonts/Stolzl/subset-Stolzl-Bold.woff2') format('woff2'),
  url('../fonts/Stolzl/subset-Stolzl-Bold.woff') format('woff'),
  url('../fonts/Stolzl/subset-Stolzl-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/subset-ProximaNovaT-Thin.woff2') format('woff2'),
  url('../fonts/ProximaNova/subset-ProximaNovaT-Thin.woff') format('woff'),
  url('../fonts/ProximaNova/subset-ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/subset-ProximaNova-Light.woff2') format('woff2'),
  url('../fonts/ProximaNova/subset-ProximaNova-Light.woff') format('woff'),
  url('../fonts/ProximaNova/subset-ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/subset-ProximaNova-Regular.woff2') format('woff2'),
  url('../fonts/ProximaNova/subset-ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova/subset-ProximaNova-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/subset-ProximaNova-Semibold.woff2') format('woff2'),
  url('../fonts/ProximaNova/subset-ProximaNova-Semibold.woff') format('woff'),
  url('../fonts/ProximaNova/subset-ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/subset-ProximaNova-Bold.woff2') format('woff2'),
  url('../fonts/ProximaNova/subset-ProximaNova-Bold.woff') format('woff'),
  url('../fonts/ProximaNova/subset-ProximaNova-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/subset-ProximaNova-Extrabld.woff2') format('woff2'),
  url('../fonts/ProximaNova/subset-ProximaNova-Extrabld.woff') format('woff'),
  url('../fonts/ProximaNova/subset-ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/subset-ProximaNova-Black.woff2') format('woff2'),
  url('../fonts/ProximaNova/subset-ProximaNova-Black.woff') format('woff'),
  url('../fonts/ProximaNova/subset-ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
