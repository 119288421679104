@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include stolzl();
  color: $primary;
  font-weight: 500;
  line-height: 1.2;

  span {
    font-weight: 400;
    @include proxima();
  }
}

h1, .h1 {
  font-size: 32px;
  @include mobile() {
    font-size: 24px;
  }
}

h2, .h2 {
  font-size: 24px;
  @include mobile() {
    font-size: 18px;
  }
}

h3, .h3 {
  font-size: 18px;
  @include mobile() {
    font-size: 16px;
  }
}

h4, .h4 {
  font-size: 16px;
  @include mobile() {
    font-size: 14px;
  }
}

h5, .h5, h6, .h6 {
  font-size: 14px;
  @include mobile() {
    font-size: 13px;
  }
}

a {
  color: $blue;
}

b, strong {
  font-weight: 700;
}

p {
  margin-bottom: 0.7em;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  //color: $blue;
  //text-decoration: underline;

  &:hover {
    text-decoration: underline;
    //color: $blue;
  }
}

ul, ol {
  margin-bottom: 1.55em;
  line-height: 1.5;
}

ul li, ol li {
  margin-bottom: .8em;
  position: relative;
}

ul {
  list-style: disc;
  padding-left: 1.55em;

  li {
    position: relative;
  }

}

ol {
  counter-reset: list;
  list-style: decimal;
  padding-left: 1.55em;
}

del, s {
  text-decoration: line-through;
}
