@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.btn {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  @include anim();
  font-size: 14px;
  line-height: 1.2;
  font-weight: normal;
  @include proxima();
  color: $white;
  background: $blue;
  padding: 9px 18px;
  border-radius: 18px;
  min-height: 36px;

  @include mobile() {
    font-size: 10px;
  }


  &:focus {
    outline: 0;
  }

  &:hover {
    color: $white;
    background: $black;
    text-decoration: none;
  }

}

