@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.notFound {
  $this: &;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__icon {
    height: 100px;
    margin-bottom: 36px;
  }

  &__title {
    margin-bottom: 43px;
  }
}