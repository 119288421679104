@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.footer {
  $this: &;

  margin-top: auto;

  &__inner {
    padding: 50px 0 55px;
    background: $primary;
    margin-top: 45px;
  }

  &__container {
    position: relative;
    color: $white;

    @include mobile() {
      max-width: 500px;
      margin: 0 auto;
    }

    a {
      color: $white;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    @include mobile() {
      width: 100%;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    @include mobile() {
      margin-left: auto;
    }

    li {
      list-style: none;
      margin: 0 15px;
      &:first-child {
        margin-left: 0;
      }
      @include mobile() {
        &:last-child {
          margin-right: 0;
        }
      }
    }

    a {
      @include stolzl();
      text-transform: uppercase;
      font-size: 13px;
      @include mobile() {
        font-size: 11px;
      }
    }
  }

  &__logo {
    display: block;
    margin-right: 35px;
  }

  &__copyright {
    margin-top: 32px;
    font-size: 13px;
  }

  &__aside {
    position: absolute;
    right: 0;
    top: 0;
    //margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include mobile() {
      margin-top: 15px;
      position: static;
      flex-direction: row;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include mobile() {
      align-items: flex-start;
    }
  }

  &__link {
    display: block;
    margin-bottom: 10px;

    svg {
      width: 14px;
      height: 14px;
      margin-left: 4px;
      display: inline-block;
    }
  }

  &__email {
    font-size: 21px;
    @include mobile() {
      font-size: 16px;
    }
  }

  &__socials {
    margin-top: 38px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include mobile() {
      margin: 0 0 0 auto;
    }

    a {
      margin-left: 14px;
    }

    svg {
      width: 22px;
      height: 18px;
    }
  }
}
