@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.header {
  $this: &;

  border-bottom: 1px solid $gray;

  @include mobile() {
    position: relative;
  }

  &__container {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile() {
      height: 50px;
    }
  }

  &__logo {
    @include mobile() {
      img {
        max-width: 120px;
        max-height: 40px;
        width: auto;
        height: auto;
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin: 0 15px;
      @include mobile() {
        margin: 0;
        width: 100%;
      }
    }

    a {
      color: $primary;
      font-size: 13px;
      text-transform: uppercase;
      @include stolzl();
      @include mobile() {
        width: 100%;
        border-bottom: 1px solid $border;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @include mobile() {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 50px;
      background: $white;
      justify-content: flex-start;
      flex-direction: column;
      border-top: 1px solid $border;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-20px);
      z-index: -1;
      @include anim();

      &--active {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
        transform: none;
        z-index: 1;
      }
    }
  }

  &__search {
    width: 270px;
    position: relative;

    @include mobile() {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      background: $white;

      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-20px);
      z-index: -1;
      @include anim();

      &--active {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
        transform: none;
        z-index: 1;
      }
    }
  }

  &__buttons {
    display: none;
    @include mobile() {
      display: flex;
      align-items: center;
    }
  }

  &__button {
    display: none;
    @include mobile() {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 18px;
      height: 18px;
    }

  }

  &__menu-button {
  }
}
