@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.head {
  $this: &;
  margin-top: 40px;
  margin-bottom: 25px;
  @include mobile() {
    margin-top: 20px;
    margin-bottom: 15px;
  }


  &__sub {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    @include mobile() {
      margin-top: 12px;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    margin-right: 18px;
  }

  &__icon {
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }

}