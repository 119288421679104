// ====================================
// colors
// ====================================

$black: #212529;
$white: #fff;
$primary: #050F58;
$blue: #2881D7;
$gray: #CFCFCF;
$border: #E3E3E3;
$muted: #757575;
// ====================================
// grid settings
// ====================================
$gutter: 15px;
$gutter-half: calc($gutter / 2);

//$breakpoint-sm: 468px
//$breakpoint-lg: 798px;
$breakpoint-mobile: 800px;

//$container-sm: 468px
$container-md: 768px;
$container-lg: 1200px;


$header_height: 60px;
