@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.search {
  $this: &;
  position: relative;
  width: 100%;

  &--active &__popup {
    transform: none;
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }

  &__input {
    display: block;
    width: 100%;
    height: 36px;
    border: 1px solid $gray;
    border-radius: calc(36px / 2);
    padding: 0 36px 0 16px;
    font-size: 14px;
    @include mobile() {
      padding-right: 65px;
      font-size: 16px;

    }
  }

  &__icon {
    width: 14px;
    height: 14px;
    color: $black;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    @include mobile() {
      right: 45px;
    }
  }

  &__popup {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 4px;
    border-radius: 4px;
    background: $white;
    border: 1px solid $border;
    min-width: 100%;

    transform: translateY(5px);
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    @include anim();

    &:empty {
      display: none !important;
    }
  }

  &__item {
    display: block;
    padding: 4px 12px;
    font-size: 14px;
  }

  &__empty {
    text-align: center;
    font-style: italic;
    font-size: 13px;
    padding: 10px 0;
  }

  &__close {
    display: none;
    @include mobile() {
      position: absolute;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 5px;
      top: 0;
    }

    svg {
      width: 14px;
      height: 14px;
    }
  }
}