@import "./style/_vars.scss"; @import "./style/_mixins.scss"; @import "./spritesmith-generated/sprite.scss";
.cities {
  $this: &;

  &--active {
    #{$this}__toggle {
      display: none;
    }

    #{$this}__rest {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      position: static;
      transform: none;
      @include anim(.3s);

    }
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__count {
    margin-left: auto;
  }

  &__toggle {
    color: $blue;
    margin-top: 5px;
    width: 100%;
  }

  &__rest {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
  }
}